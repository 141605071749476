import moment from 'moment'

export const FormatDateFilter = {
    install(Vue) {
        Vue.filter('toDateTime', (input) => {
            if (!isNaN(Date.parse(input))) {
                return moment.utc(input).format(window.culture.dateFormat.toUpperCase() + " " + timeFormatSpecificToMoment(window.culture.timeWithMinutesAndSecondsFormat));
            }
            else if (!input) {
                return "-";
            }
            return input;
        });

        Vue.filter('toDateTimeWithoutSeconds', (input) => {
            if (!isNaN(Date.parse(input))) {
                return moment.utc(input).format(window.culture.dateFormat.toUpperCase() + " " + timeFormatSpecificToMoment(window.culture.timeWithMinutesFormat));
            }
            else if (!input) {
                return "-";
            }
            return input;
        });

        Vue.filter('toDate', (input) => {
            if (!isNaN(Date.parse(input))) {
                return moment.utc(input).format(window.culture.dateFormat.toUpperCase());
            }
            else if (!input) {
                return "-";
            }
            return input;
        });

        Vue.filter('toFullYearDate', (input) => {
            if (!isNaN(Date.parse(input))) {
                const YEAR_REGEXP = /Y+/;
                return moment.utc(input).format(window.culture.dateFormat.toUpperCase().replace(YEAR_REGEXP, 'YYYY'));
            }
            else if (!input) {
                return "-";
            }
            return input;
        });

        Vue.filter('isBeforeToday', (input) => {            
            let inputDate = moment.utc(input).format(window.culture.dateFormat.toUpperCase() + " " + timeFormatSpecificToMoment(window.culture.timeWithMinutesAndSecondsFormat));
            let currentDate = moment(new Date()).format(window.culture.dateFormat.toUpperCase() + " " + timeFormatSpecificToMoment(window.culture.timeWithMinutesAndSecondsFormat));
            if (inputDate > currentDate) {
                return false;               
            } else {
                return true;
            } 
        });

        Vue.filter('toShortDateOrTime', (input) => {
            if(!isNaN(Date.parse(input))) {
                let currentDate = new moment();
                let inputDate = new moment(input);
                let isTheSameDay = moment(currentDate).isSame(inputDate, 'day');
                let isTheSameYear = moment(currentDate).isSame(inputDate, 'year');
                if (isTheSameDay) {
                    let dateFormat = timeFormatSpecificToMoment(window.culture.timeWithMinutesFormat);
                    return moment.utc(inputDate).format(dateFormat);
                } 
                else if (isTheSameYear) {
                    let dateFormat = window.culture.dateWithoutYearFormat.toUpperCase();
                    return moment.utc(inputDate).format(dateFormat);
                }
                else {
                    return moment.utc(inputDate).format(window.culture.dateFormat.toUpperCase());
                }
            } else if (!input) {
                return '-';
            }
            return input;
        });

        function timeFormatSpecificToMoment(currentTimeFormat)
        {
            if(currentTimeFormat.includes('tt')){
                currentTimeFormat = currentTimeFormat.replace('tt', 'A');
            }
            return currentTimeFormat;
        }   
    }    
}
