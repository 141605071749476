
export const ReplaceStringWithPlaceholdersPlugin = {
  install(Vue) {

    const PLACEHOLDER_REGEXP = /{(\w+)}/g;

    Vue.prototype.$replaceStringWithPlaceholders = function () {
      if (arguments.length === 0) {
        return "";
      } else if (arguments.length === 1) {
        return arguments[0];
      } else {
        let stringWithPlaceholders = arguments[0];
        const stringReplacements = arguments[1];
        return stringWithPlaceholders.replace(
          PLACEHOLDER_REGEXP,
          (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
          Object.prototype.hasOwnProperty.call(stringReplacements, placeholderWithoutDelimiters) ?
          stringReplacements[placeholderWithoutDelimiters] : placeholderWithDelimiters
        );
      }
    }
  }
}