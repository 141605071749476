export const FormatDataFilter = {
    install(Vue) {
        Vue.filter('toMinusIfNull', (input) => {
            if (!input) {
                return "-";
            }
            return input;
        });
    }    
}
