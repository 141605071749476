export const ErrorMessageConfigPlugin = {
    install(Vue) {
        Vue.prototype.$errorMessageConfig = () => {
            return {
                variant: 'danger',
                title: 'Error',
                solid: true
            }
        }
    }
}