export const StorageOptions = {
    namespace: 'tOption__',
    name: 'session',
    storage: 'local',
};

export const BootstrapVueOptions = {
    BFormDatepicker: {
        labelHelp: '',
        dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' }
    }
}