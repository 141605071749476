import { X_XSRF_TOKEN, XSRF_TOKEN } from '../cookies';

export const ExecutionMomentType = {
    install(Vue) {
        Vue.prototype.$ExecutionMomentType = {
            IMMEDIATE: "Immediate",
            AS_SOON_AS_POOSIBLE: "AsSoonAsPossible",
            WITH_PERIOD: "WithinPeriod",
            AT_DATE: "AtDate",
            AT_PRICE: "AtPrice"
        }
    }
}

export const HttpStatusCodes = {
    install(Vue) {
        Vue.prototype.$HttpStatusCodes = {
            OK: 200,
            CREATED: 201,
            ACCEPTED: 202,
            NO_CONTENT: 204,
            BAD_REQUEST: 400,
            UNAUTHORIZED: 401,
            FORBIDDEN: 403,
            NOT_FOUND: 404,
            UNPROCESSABLE_ENTITY: 422,
            INTERNAL_SERVER_ERROR: 500,
            SERVICE_UNAVAILABLE: 503
        }
    }
}

export const QuantityInputTypeEnum = {
    install(Vue) {
        Vue.prototype.$QuantityInputType = {
            ALL: "All",
            SELECT: "Select",
            ALL_AND_SELECT: "AllAndSelect"
        }
    }
}

export const TransactionType = {
    install(Vue) {
        Vue.prototype.$TransactionType = {
            INTERNAL_EXCHANGE: "InternalExchangeInstruction",
            BUY: "BuyInstruction",
            EXIT: "ExitInstruction",
            EXERCISE: "ExerciseInstruction"
        }
    }
}

export const AntiforgeryContstants = {
    install(Vue) {
        Vue.prototype.$AntiforgeryConstants = {
            RequestToken: XSRF_TOKEN,
            RequestHeader: X_XSRF_TOKEN
        }
    }
}

export const SessionKeys = {
    install(Vue) {
        Vue.prototype.$SessionKeys = {
            Heartbeat: "Heartbeat",
            Expires: "Expires",
            InactivityPrompt: "InactivityPrompt",
            ForcedLogout: "ForcedLogout"
        }
    }
}

export const BankAccountType = {
    install(Vue) {
        Vue.prototype.$BankAccountType = {
            MONEY: 'eatMoney',
            SECURITY: 'eatSecurity',
        }
    }
}

export const OrderTypes = {
    install(Vue) {
        Vue.prototype.$OrderTypes = {
            Exits: 'Exits',
            Exercises: 'Exercises',
            Buy: 'Buy',
            MoneyTransfer: 'MoneyTransfer'
        }
    }
}

export const ExternalAccountArea = {
    install(Vue) {
        Vue.prototype.$ExternalAccountArea = {
            None: 'eaaNone',
            SEPA: 'eaaSEPA',
        }
    }
}

export const ExternalAccountRouting = {
    install(Vue) {
        Vue.prototype.$ExternalAccountRouting = {
            None: 'eaaNone',
            BIC: 'earBIC',
            BICorABA: 'earBICorABA',
            BICorBSB: 'earBICorBSB',
            BICorBranchCode: 'earBICorBranchCode',
            OptionalBICandBranchSortCode: 'earOptionalBICandBranchSortCode',
            BICandIBAN: 'earBICandIBAN',
            IBAN: 'earIBAN',
            BICorTransitNumber: 'earBICorTransitNumber',
        }
    }
}

export const ExerciseScenarioType = {
    install(Vue) {
        Vue.prototype.$ExerciseScenarioType = {
            DELIVERY_OF_SHARES: 'detDelivery',
            SALE_OF_SHARES: 'detSale',
            SALE_AND_DELIVERY_OF_SHARES: 'detSaleAndDelivery',
        }
    }
}

export const PlanControlTags = {
    install(Vue) {
        Vue.prototype.$PlanControlTags = {
            STOCK_AWARD: 'StockAward',
            STOCK_OPTION: 'StockOption'
        }
    }
}

export const DividendType = {
    install(Vue) {
        Vue.prototype.$DividendType = {
            Money: 'dodtMoney',
            Security: 'dodtSecurity',
            SecurityAndMoney: 'dodtSecurityAndMoney'
        }
    }
}

export const AccountActionType = {
    install(Vue) {
        Vue.prototype.$AccountActionType = {
            ADD: 'ADD',
            EDIT: 'EDIT',
            REMOVE: 'REMOVE',
            VIEW: 'VIEW',
            CANCEL: 'CANCEL',
            DEFAULT: 'DEFAULT'
        }
    }
}

export const QuestionType = {
    install(Vue) {
        Vue.prototype.$QuestionType = {
            StandardChoices: 'qqtStandardChoices',
            DefinedChoices: 'qqtDefinedChoices',
            Open: 'qqtOpen'
        }
    }
}
