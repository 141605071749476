import numeral from 'numeral'

export const SetDecimalSeparatorBySettings = {
  install(Vue) {
    Vue.prototype.$setDecimalSeparatorBySettings = (value) => {
      return value ? numeral(value).format('0.[000000000]') : value;
    }
  }
}

export const ConvertToDecimal = {
  install(Vue) {
    Vue.prototype.$convertToDecimal = (value) => {
      return numeral(value).value()
    }
  }
}
