import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import Storage from 'vue-ls';
import { StorageOptions, BootstrapVueOptions } from './options';
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.use(Storage, StorageOptions);

import Vuetify from './vuetify';
import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';

Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(BootstrapVue, BootstrapVueOptions);
Vue.use(VueCookies);
Vue.use(Vuelidate);

Vue.component('modal-basic', () => import('./Components/Vue/Modals/modal.component.vue'));
Vue.component('portlet-layout', () => import('./Components/Vue/Portlet/portlet.component.vue'));
Vue.component('loading-spinner', () => import('./Components/Vue/LoadingSpinner/loading-spinner.component.vue'));
Vue.component('select-element', () => import('./Components/Vue/Elements/select.component.vue'));
Vue.component('input-element', () => import('./Components/Vue/Elements/input.component.vue'));
Vue.component('textarea-element', () => import('./Components/Vue/Elements/textarea.component.vue'));
Vue.component('disclaimers', () => import('./Components/Vue/Partials/disclaimers.component.vue'));
Vue.component('payments-result', () => import('./Components/Vue/Partials/payments-result.component.vue'));
Vue.component('component-spinner', () => import('./Components/Vue/ComponentSpinner/component-spinner.vue'));
Vue.component('portlet-tab', () => import('./Components/Vue/Portlet/portlet-tab.component.vue'));
Vue.component('alert-component', () => import('./Components/Vue/Alert/alert.component.vue'));
Vue.component('portlet-row', () => import('./Components/Vue/Portlet/portlet-row.component.vue'));
Vue.component('datepicker-element', () => import('./Components/Vue/Elements/datepicker.component.vue'));
Vue.component('datatable-mobile-header', () => import('./Components/Vue/Datatable/datatable-mobile-header.component.vue'));
Vue.component('total-block', () => import('./Components/Vue/Partials/total-block.component.vue'));
Vue.component('security-price-block', () => import('./Components/Vue/Partials/security-price-block.component.vue'));
Vue.component('prices-chart', () => import('./Components/Vue/Partials/prices-chart.component.vue'));
Vue.component('order-types-link', () => import('./Components/Vue/Partials/order-types-link.component.vue'));
Vue.component('datatable-search', () => import('./Components/Vue/Datatable/datatable-search.component.vue'));
Vue.component('datatable-search-header', () => import('./Components/Vue/Datatable/datatable-search-header.component.vue'));
Vue.component('upload-element', () => import('./Components/Vue/Elements/upload.component.vue'));
Vue.component('timeline-container', () => import('./Components/Vue/Elements/timeline-container.component.vue'));
Vue.component('timeline-step', () => import('./Components/Vue/Elements/timeline-step.component.vue'));
Vue.component('logo-image', () => import('./Components/Vue/LogoImage/logo-image.component.vue'));
Vue.component('custom-dropdown', () => import('./Components/Vue/Partials/custom-dropdown.component.vue'));
Vue.component('tooltip', () => import('./Components/Vue/Elements/tooltip.component.vue'));
Vue.component('qr-code', VueQRCodeComponent);
Vue.component('last-orders', () => import('./Components/Vue/Partials/last-orders.component.vue'));
Vue.component('last-transactions', () => import('./Components/Vue/Partials/last-transactions.component.vue'));
Vue.component('datatable-pagination', () => import('./Components/Vue/Datatable/datatable-pagination.component.vue'));

import { DictionaryShowKeysPlugin } from './Plugins/dictionary-show-keys.plugin';
import { ReplaceNullOrEmptyPlugin } from './Plugins/replace-null-or-empty.plugin';
import { GetUrlPathWithoutQueryStringPlugin } from './Plugins/get-url-path-without-query-string.plugin';
import { ErrorMessageConfigPlugin } from './Plugins/error-message-config.plugin';
import { ExecutionMomentType, QuantityInputTypeEnum, TransactionType, AntiforgeryContstants, HttpStatusCodes, SessionKeys, BankAccountType, OrderTypes, ExternalAccountArea, ExternalAccountRouting, ExerciseScenarioType, PlanControlTags, DividendType, AccountActionType, QuestionType } from './Plugins/enums.plugin';
import { ObjectHelperPlugin } from './Plugins/object-helper.plugin';
import { FormatDateFilter } from './Filters/format-date.filter';
import { FormatDataFilter } from './Filters/format-data.filter';
import { WindowSizesPlugin } from './Plugins/window-sizes.plugin';
import { ReplaceStringWithPlaceholdersPlugin } from './Plugins/replace-string-with-placeholders.plugin.js';
import { SanitizerPlugin } from './Plugins/sanitizer.plugin.js';
import { SetDecimalSeparatorBySettings, ConvertToDecimal } from './Plugins/set-decimal-separator.js';
import { SetPageTitle } from './Plugins/set-page-title.plugin.js';

Vue.use(GetUrlPathWithoutQueryStringPlugin);
Vue.use(ReplaceNullOrEmptyPlugin);
Vue.use(ErrorMessageConfigPlugin);
Vue.use(ObjectHelperPlugin);
Vue.use(FormatDateFilter);
Vue.use(FormatDataFilter);
Vue.use(WindowSizesPlugin);
Vue.use(ReplaceStringWithPlaceholdersPlugin);
Vue.use(SanitizerPlugin);
Vue.use(DictionaryShowKeysPlugin);
Vue.use(SetDecimalSeparatorBySettings);
Vue.use(ConvertToDecimal);
Vue.use(SetPageTitle);

Vue.use(ExecutionMomentType)
    .use(QuantityInputTypeEnum)
    .use(TransactionType)
    .use(AntiforgeryContstants)
    .use(HttpStatusCodes)
    .use(SessionKeys)
    .use(BankAccountType)
    .use(OrderTypes)
    .use(ExternalAccountArea)
    .use(ExternalAccountRouting)
    .use(ExerciseScenarioType)
    .use(PlanControlTags)
    .use(DividendType)
    .use(AccountActionType)
    .use(QuestionType);