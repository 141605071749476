import Vuetify from 'vuetify'
const opts = {
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                background: '#EFEFEF',
                error: "#FFE0E0",
                success: "#DEF4E9",
                warning: "#FFF2C3",
                info: "#DEEBFF"
            }
        }
    }
}
export default new Vuetify(opts)