import "core-js/stable";
import "regenerator-runtime/runtime";

import Modernizr from 'modernizr'; // eslint-disable-line no-unused-vars

import cssVars from 'css-vars-ponyfill';

if (!(window.CSS && window.CSS.supports.bind(window.CSS))) {
    cssVars({
        preserveStatic: false
    });
}

import $ from 'jquery'

import 'bootstrap'

import Vue from 'vue'

import 'js-storage'
import 'apexcharts'
import 'moment'
import 'dom4'


// TODO: Remove when all JS migrates to Webpack entries
//       Actually, all vendor.js can be removed and replaced with 
//       imports for each individual entry(webpack will create vendor
//       for shared bits on it's own).
window.$ = window.jQuery = $;
global.Vue = Vue;