export const DictionaryShowKeysPlugin = {
    install(Vue) {
        Vue.prototype.$showKeys = (context, url) => {
            if (context.$route) {
                var keys = Object.keys(context.$route.query).map(x => x.toLowerCase());
                return keys.includes('showkeys') ? (url.indexOf('?') === -1 ? '?' : '&') + 'showKeys=1' : '';
            }
            return '';
        }
    }
}